import React from "react"

export default function Image({image, caption}) {
    return (
        <figure>
            <img src={image} width="100%"/>
            <figcaption>{caption}</figcaption>
        </figure>
    )
}
