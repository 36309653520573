import * as React from "react";
import Layout from "../../components/layout";
import Image from "../../components/image";
import vega from "./img/vega.jpg";
import linnogjonas from "./img/linnogjonasski.jpg";
import lyderski from "./img/lyderski.jpg";
import justakebrett from "./img/justakebrett.jpg";
import nelly from "./img/nelly.jpg";
import PodcastPlayer from "../../components/podcastplayer";

export default () => (
    <Layout title={"Julekort 2021"}>
        <div id="content" class="content-region">
            <h1>🎄 God Jul ️🎅</h1>
            <h4>De beste ønsker til alle venner og kjente, takk for året som har gått!</h4>
            <h4>Hilsen Just, Lyder, Linn, Jonas og Nelly ❤️</h4>
        </div>
        <div id="content" class="content-region">
            <h3>Årets julepodcast:</h3>
            <PodcastPlayer
                podcastUrl="https://drive.google.com/uc?export=download&id=1ettmAO2RMDTW-pj7oUFd-vvQits9ajPc"
                caption=""/>
        </div>
        <div id="content" class="content-region">
            <div>
                <h3>Bilder fra året som har gått</h3>
                <Image image={vega} caption="Just og Lyder, Vega Mai 2021"/>
                <Image image={lyderski} caption="Lyder på ski, Oppdal 2021"/>
                <Image image={justakebrett} caption="Just på aketur, Byåsen 2021"/>
                <Image image={linnogjonas} caption="Linn og Jonas, Oppdal 2021"/>
                <Image image={nelly} caption="Nelly"/>
            </div>
        </div>
    </Layout>
)
