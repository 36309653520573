import React from "react"

export default function PodcastPlayer({podcastUrl, caption}) {
    return (
        <div>
            <figure>
                <figcaption>{caption}</figcaption>
                <audio controls preload="none">
                    <source src={podcastUrl} type="audio/mpeg"/>
                    Your browser does not support the
                    <code>audio</code> element.
                </audio>
            </figure>
            <div>
                <a href={podcastUrl}>💾
                    Last
                    ned</a>
            </div>
        </div>
    )
}
